(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/flags/assets/javascripts/helpers/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/lb-ui/flags/assets/javascripts/helpers/handlebars-helpers.js');
"use strict";


(function (svs) {
  'use strict';

  var shared;
  if (typeof exports === 'object') {
    shared = require('../shared.es6');
    registerHelpers(require('hbs'));
  } else {
    shared = svs.lb_ui.flags;
    registerHelpers(Handlebars);
  }
  function registerHelpers(hbs) {
    hbs.registerHelper('lb_ui_flags_getFlag', function (iso, key) {
      return shared.getFlag(iso, key);
    });
  }
})(svs);

 })(window);