(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/flags/assets/javascripts/shared.js') >= 0) return;  svs.modules.push('/components/lb-ui/flags/assets/javascripts/shared.js');
"use strict";



var svs = svs || {};
(function (svs) {
  'use strict';

  svs.lb_ui = svs.lb_ui || {};
  svs.lb_ui.flags = svs.lb_ui.flags || {};
  var cdn = '';
  if (typeof exports === 'object') {
    var trinidad = require('trinidad-core').core;
    cdn = trinidad.options.cdn;
  } else {
    cdn = svs.core.config.data.svsconfig.cdn;
  }
  var availiableFlags = [{
    iso: 'ABW',
    name: 'Aruba',
    url: '/images/lb-ui/flags/abw-33e59346dfb2e306813a372a30a719ed879bab4b.svg'
  }, {
    iso: 'AFG',
    name: 'Afghanistan',
    url: '/images/lb-ui/flags/afg-c8cb20192136d96af041714bfebf9fff5712dbb5.svg'
  }, {
    iso: 'AGO',
    name: 'Angola',
    url: '/images/lb-ui/flags/ago-1056338446d6e82e3776cf2af824c79ea4851e33.svg'
  }, {
    iso: 'AIA',
    name: 'Anguilla',
    url: '/images/lb-ui/flags/aia-6e96af46e560b14be6a9a43e8188afff1385eb65.svg'
  }, {
    iso: 'ALA',
    name: 'Åland Islands',
    url: '/images/lb-ui/flags/ala-ec525f6d74351bd640ffcf89d08d570aee909c0f.svg'
  }, {
    iso: 'ALB',
    name: 'Albania',
    url: '/images/lb-ui/flags/alb-d36c4f4cf5e393af6d5a7582a683f17e58508d65.svg'
  }, {
    iso: 'AND',
    name: 'Andorra',
    url: '/images/lb-ui/flags/and-1b0e201064038668617f877ac4c3aa26a17ee39c.svg'
  }, {
    iso: 'ARE',
    name: 'United Arab Emirates',
    url: '/images/lb-ui/flags/are-84300035325ef7cb36579e7fac7d5c30ac1be531.svg'
  }, {
    iso: 'ARG',
    name: 'Argentina',
    url: '/images/lb-ui/flags/arg-79aeeac7815a78ba9c2ee56db5ad2949225fcd03.svg'
  }, {
    iso: 'ARM',
    name: 'Armenia',
    url: '/images/lb-ui/flags/arm-09f4233e57ec16840d4a64a9e4de6937b1dabdea.svg'
  }, {
    iso: 'ASM',
    name: 'American Samoa',
    url: '/images/lb-ui/flags/asm-3d992d2ab7f7941a29272971418842640a95ed45.svg'
  }, {
    iso: 'ATA',
    name: 'Antarctica',
    url: '/images/lb-ui/flags/ata-00d6d63557b5ab24b031daa74bfbb1d4ea673af0.svg'
  }, {
    iso: 'ATF',
    name: 'French Southern and Antarctic Territories',
    url: '/images/lb-ui/flags/atf-ebe262d82b2df98a6e67c823eef408fe43193b79.svg'
  }, {
    iso: 'ATG',
    name: 'Antigua And Barbuda',
    url: '/images/lb-ui/flags/atg-38f5f7f0d3af94b70441abdfa72272a6d83a5b5e.svg'
  }, {
    iso: 'AUS',
    name: 'Australia',
    url: '/images/lb-ui/flags/aus-501c89c79e831af12bdecb0ceb5f3f5b3ef3f1fa.svg'
  }, {
    iso: 'AUT',
    name: 'Austria',
    url: '/images/lb-ui/flags/aut-b25ab203d8200728bbc9d9c89f535d38a8625233.svg'
  }, {
    iso: 'AZE',
    name: 'Azerbaijan',
    url: '/images/lb-ui/flags/aze-5293083aeea693db3cc931968f17ec154f4f26bc.svg'
  }, {
    iso: 'BDI',
    name: 'Burundi',
    url: '/images/lb-ui/flags/bdi-9326c1235aa688843a3c71cdf3d8a76a96c3072a.svg'
  }, {
    iso: 'BEL',
    name: 'Belgium',
    url: '/images/lb-ui/flags/bel-040dcaca7c8e3d3c9f3c5e2d691408450a750147.svg'
  }, {
    iso: 'BEN',
    name: 'Benin',
    url: '/images/lb-ui/flags/ben-4436edd48cca6c2272c4692a795ac1cda66938c9.svg'
  }, {
    iso: 'BES',
    name: 'Bonaire, Saint Eustatius And Saba',
    url: '/images/lb-ui/flags/bes-086bfa3fb5a8eb0d63b3e0cb38415c5e8e0a260f.svg'
  }, {
    iso: 'BFA',
    name: 'Burkina Faso',
    url: '/images/lb-ui/flags/bfa-26671b4d826ccc0383d5551844f6c6da34287e93.svg'
  }, {
    iso: 'BGD',
    name: 'Bangladesh',
    url: '/images/lb-ui/flags/bgd-c8a3b92a6f35e0bb7bff4cc34205ed2e3eff3be8.svg'
  }, {
    iso: 'BGR',
    name: 'Bulgaria',
    url: '/images/lb-ui/flags/bgr-f891498ee063397b9c843bff4ee8cb018c4f1790.svg'
  }, {
    iso: 'BHR',
    name: 'Bahrain',
    url: '/images/lb-ui/flags/bhr-8e17d2074ad5f34183020fb1c726535ec0ac0380.svg'
  }, {
    iso: 'BHS',
    name: 'Bahamas',
    url: '/images/lb-ui/flags/bhs-94f93fdf468472231712a7a1de85cb084b854353.svg'
  }, {
    iso: 'BIH',
    name: 'Bosnia &amp; Herzegovina',
    url: '/images/lb-ui/flags/bih-bc0094cc04fbd2185296822f9099d4c621b4bfc4.svg'
  }, {
    iso: 'BLM',
    name: 'Saint Barthélemy',
    url: '/images/lb-ui/flags/blm-2078590e9c4ef1b82622faf09b26b1b3d24f4bd3.svg'
  }, {
    iso: 'BLR',
    name: 'Belarus',
    url: '/images/lb-ui/flags/blr-bcb18fc03f2f5122e0495723a1e0e6a705400327.svg'
  }, {
    iso: 'BLZ',
    name: 'Belize',
    url: '/images/lb-ui/flags/blz-072c83e87a7e2764386bf723e9c173cabbddb77b.svg'
  }, {
    iso: 'BMU',
    name: 'Bermuda',
    url: '/images/lb-ui/flags/bmu-a7e924a9deca0d7bb61f83ce3543f4e025ac87fa.svg'
  }, {
    iso: 'BOL',
    name: 'Bolivia, Plurinational State Of',
    url: '/images/lb-ui/flags/bol-6dff98037c75577fe7c4b0bdb0af770c8fcc6db6.svg'
  }, {
    iso: 'BRA',
    name: 'Brazil',
    url: '/images/lb-ui/flags/bra-431f861651f97fc956ba0315149a757f454a7a79.svg'
  }, {
    iso: 'BRB',
    name: 'Barbados',
    url: '/images/lb-ui/flags/brb-af7ec6034d1fc7c134d31f06a05736dbc052b630.svg'
  }, {
    iso: 'BRN',
    name: 'Brunei Darussalam',
    url: '/images/lb-ui/flags/brn-b84eb648ede47be65441a0f480832ebbbc199511.svg'
  }, {
    iso: 'BTN',
    name: 'Bhutan',
    url: '/images/lb-ui/flags/btn-a8c6f4f6d62f78f0b0d7832cd2a1d0015ff905b7.svg'
  }, {
    iso: 'BVT',
    name: 'Bouvet Island',
    url: '/images/lb-ui/flags/bvt-6a04222f3653f00881a01c01ef344da2d24ff369.svg'
  }, {
    iso: 'BWA',
    name: 'Botswana',
    url: '/images/lb-ui/flags/bwa-0ccef10d8a95149d4453b197f161a06efc244daf.svg'
  }, {
    iso: 'CAF',
    name: 'Central African Republic',
    url: '/images/lb-ui/flags/caf-0d2f269e44fac5d8522c54894f605ef0012c9d91.svg'
  }, {
    iso: 'CAN',
    name: 'Canada',
    url: '/images/lb-ui/flags/can-33164558193c2fe45ef7516b976c957b139cd39f.svg'
  }, {
    iso: 'CCK',
    name: 'Cocos (Keeling) Islands',
    url: '/images/lb-ui/flags/cck-611110157932026d0688da9200f2563533f01c5b.svg'
  }, {
    iso: 'CHE',
    name: 'Switzerland',
    url: '/images/lb-ui/flags/che-a880477936b0459ac0f8b9479d01943f4f7b8a80.svg'
  }, {
    iso: 'CHL',
    name: 'Chile',
    url: '/images/lb-ui/flags/chl-00bb640d5991ab0b46ccaa237d6128d3dc951d7d.svg'
  }, {
    iso: 'CHN',
    name: 'China',
    url: '/images/lb-ui/flags/chn-0b6e886a172e99e3572fbae535b05c6d37b17a8b.svg'
  }, {
    iso: 'CIV',
    name: 'Côte d&#x27;Ivoire',
    url: '/images/lb-ui/flags/civ-73e9c3fd34fb441105425f928d31d9d5d820eb0c.svg'
  }, {
    iso: 'CMR',
    name: 'Cameroon',
    url: '/images/lb-ui/flags/cmr-a6b271ab0c752c1c4aa0a7cfe78ebb206f0a6182.svg'
  }, {
    iso: 'COD',
    name: 'Democratic Republic Of Congo',
    url: '/images/lb-ui/flags/cod-e927b0d3ce1b14d1e526608c3ea499b2b8eeeacb.svg'
  }, {
    iso: 'COG',
    name: 'Republic Of Congo',
    url: '/images/lb-ui/flags/cog-ad253b017d838725df6d03ebad08f14f55a92c74.svg'
  }, {
    iso: 'COK',
    name: 'Cook Islands',
    url: '/images/lb-ui/flags/cok-86a89513f67169aee2613819faa335f3b3c6a1c4.svg'
  }, {
    iso: 'COL',
    name: 'Colombia',
    url: '/images/lb-ui/flags/col-120fa2b2f67f825abef4790ea0afc8c2800d093d.svg'
  }, {
    iso: 'COM',
    name: 'Comoros',
    url: '/images/lb-ui/flags/com-9eb03fd2e4590bbcebffd69fad9efe8096f67c6f.svg'
  }, {
    iso: 'CPV',
    name: 'Cabo Verde',
    url: '/images/lb-ui/flags/cpv-a4cd8e0058407da7d9ee8e86320a12a9c70a9e84.svg'
  }, {
    iso: 'CRI',
    name: 'Costa Rica',
    url: '/images/lb-ui/flags/cri-42c475a7efed7250d0b746944e2333b78a93c00d.svg'
  }, {
    iso: 'CUB',
    name: 'Cuba',
    url: '/images/lb-ui/flags/cub-8fbb54d1427b0d5e68138941c81570b8a086dfa2.svg'
  }, {
    iso: 'CUW',
    name: 'Curacao',
    url: '/images/lb-ui/flags/cuw-6b2a878bc2c12ffff5f856ef1ffc52b67730fb75.svg'
  }, {
    iso: 'CXR',
    name: 'Christmas Island',
    url: '/images/lb-ui/flags/cxr-d5190d9dc20354151e226e1f0b72897bedbfa062.svg'
  }, {
    iso: 'CYM',
    name: 'Cayman Islands',
    url: '/images/lb-ui/flags/cym-909d7b76876ad0ee855e1dfa73f63b113d7a8a37.svg'
  }, {
    iso: 'CYP',
    name: 'Cyprus',
    url: '/images/lb-ui/flags/cyp-a4263e7f79f890f97b9479de5e9db354c536e1b6.svg'
  }, {
    iso: 'CZE',
    name: 'Czech Republic',
    url: '/images/lb-ui/flags/cze-dc72f8279e49dab99372034143c4047e254595c5.svg'
  }, {
    iso: 'DEU',
    name: 'Germany',
    url: '/images/lb-ui/flags/deu-23965f619bf9c2fcee45811adaeb8f8f072b3913.svg'
  }, {
    iso: 'DJI',
    name: 'Djibouti',
    url: '/images/lb-ui/flags/dji-66a91be58aa8b6de39d5e0009482d6b075f9ae5d.svg'
  }, {
    iso: 'DMA',
    name: 'Dominica',
    url: '/images/lb-ui/flags/dma-00fe917b1014d9bf457ef200bb23f33c68fa18b4.svg'
  }, {
    iso: 'DNK',
    name: 'Denmark',
    url: '/images/lb-ui/flags/dnk-ee0dd725b86aa559ff9163e43caaffb6e38a233b.svg'
  }, {
    iso: 'DOM',
    name: 'Dominican Republic',
    url: '/images/lb-ui/flags/dom-69195f1d5fbeeec7f8f76ebf21c92047aa4e916c.svg'
  }, {
    iso: 'DZA',
    name: 'Algeria',
    url: '/images/lb-ui/flags/dza-b58dae42e39c19d635b25e9c069a803fcb72c740.svg'
  }, {
    iso: 'ECU',
    name: 'Ecuador',
    url: '/images/lb-ui/flags/ecu-d4d44bb1e50e91557ea41c448df059cc52d53021.svg'
  }, {
    iso: 'EGY',
    name: 'Egypt',
    url: '/images/lb-ui/flags/egy-02914d803a02f65d79c0517860d95f37df0746cd.svg'
  }, {
    iso: 'ENG',
    name: 'England',
    url: '/images/lb-ui/flags/eng-4b3b1e761f726432872f929092c6e4a69b095105.svg'
  }, {
    iso: 'ERI',
    name: 'Eritrea',
    url: '/images/lb-ui/flags/eri-5f8d3ff1d49072bb0a515e4fd45a81018067e01e.svg'
  }, {
    iso: 'ES-CT',
    name: 'Catalonia',
    url: '/images/lb-ui/flags/es-ct-465b45d3f322bb4494696a667bdfe6cd5114a181.svg'
  }, {
    iso: 'ESH',
    name: 'Western Sahara',
    url: '/images/lb-ui/flags/esh-942004207163483b70a3a86ea370a983c9f131ee.svg'
  }, {
    iso: 'ESP',
    name: 'Spain',
    url: '/images/lb-ui/flags/esp-767573be20c984f7f6d35f4579bc030736010527.svg'
  }, {
    iso: 'EST',
    name: 'Estonia',
    url: '/images/lb-ui/flags/est-9d02300160bc10d90938b1a4abf1b0aee0ddda46.svg'
  }, {
    iso: 'ETH',
    name: 'Ethiopia',
    url: '/images/lb-ui/flags/eth-ba7fd9a52993a7f600bad3d6f1401ca7c21604b7.svg'
  }, {
    iso: 'FIN',
    name: 'Finland',
    url: '/images/lb-ui/flags/fin-1fe2d712409b2f1e6470be5b2670f276696c383d.svg'
  }, {
    iso: 'FJI',
    name: 'Fiji',
    url: '/images/lb-ui/flags/fji-063f2c506bd2f79045de369cbca3c0978895f574.svg'
  }, {
    iso: 'FLK',
    name: 'Falkland Islands',
    url: '/images/lb-ui/flags/flk-73a8389f41346a4946f57ab2409c1600db9363bc.svg'
  }, {
    iso: 'FRA',
    name: 'France',
    url: '/images/lb-ui/flags/fra-2078590e9c4ef1b82622faf09b26b1b3d24f4bd3.svg'
  }, {
    iso: 'FRO',
    name: 'Faroe Islands',
    url: '/images/lb-ui/flags/fro-5bdd8b9d94e282df674e402ed7ee51f98ea74569.svg'
  }, {
    iso: 'FSM',
    name: 'Micronesia, Federated States Of',
    url: '/images/lb-ui/flags/fsm-ff08f8725769dda30bc50fb33ffd560cd50d4c4d.svg'
  }, {
    iso: 'GAB',
    name: 'Gabon',
    url: '/images/lb-ui/flags/gab-b6f23369ef787626319b1a625f4ce3ac0c12c85b.svg'
  }, {
    iso: 'GBR',
    name: 'United Kingdom',
    url: '/images/lb-ui/flags/gbr-ba03be2200ab1adf3d4c12080131f5f43e3aef66.svg'
  }, {
    iso: 'GEO',
    name: 'Georgia',
    url: '/images/lb-ui/flags/geo-c2f0be091f73f35159ee55521aef28a470028116.svg'
  }, {
    iso: 'GGY',
    name: 'Guernsey',
    url: '/images/lb-ui/flags/ggy-5674189d033584faa1667ea2dc8490ad1fc07bbe.svg'
  }, {
    iso: 'GHA',
    name: 'Ghana',
    url: '/images/lb-ui/flags/gha-6087a214adc5b805d9ea41dcf82d37b20579948d.svg'
  }, {
    iso: 'GIB',
    name: 'Gibraltar',
    url: '/images/lb-ui/flags/gib-2c243e53feb9b8b61fc7619f21cd97d980a796f2.svg'
  }, {
    iso: 'GIN',
    name: 'Guinea',
    url: '/images/lb-ui/flags/gin-c7327b041f22ec48ccb782cb661c2de774de985e.svg'
  }, {
    iso: 'GLP',
    name: 'Guadeloupe',
    url: '/images/lb-ui/flags/glp-2078590e9c4ef1b82622faf09b26b1b3d24f4bd3.svg'
  }, {
    iso: 'GMB',
    name: 'Gambia',
    url: '/images/lb-ui/flags/gmb-0e15adf0e7410a08dbd38dd65ffcfdbc8f2c6c31.svg'
  }, {
    iso: 'GNB',
    name: 'Guinea-bissau',
    url: '/images/lb-ui/flags/gnb-4f037d6926064879ea871e2c3f5e95859409b2a6.svg'
  }, {
    iso: 'GNQ',
    name: 'Equatorial Guinea',
    url: '/images/lb-ui/flags/gnq-53a7c9e0a8f7d798850e02446201d6c705be2c0a.svg'
  }, {
    iso: 'GRC',
    name: 'Greece',
    url: '/images/lb-ui/flags/grc-aa157ea1df40ce7dea838b5a2a266804b8a59535.svg'
  }, {
    iso: 'GRD',
    name: 'Grenada',
    url: '/images/lb-ui/flags/grd-47c83e77bd2ba1aff883d5e4f6295a87e1e702bc.svg'
  }, {
    iso: 'GRL',
    name: 'Greenland',
    url: '/images/lb-ui/flags/grl-0904f158c01f4447fb23c01509647cd786fc0c0c.svg'
  }, {
    iso: 'GTM',
    name: 'Guatemala',
    url: '/images/lb-ui/flags/gtm-64c4d8a20831de291457745a0b8b86f45b868cd1.svg'
  }, {
    iso: 'GUF',
    name: 'French Guiana',
    url: '/images/lb-ui/flags/guf-1da6dc74a772811c1cb5333fa729f1eec56bf068.svg'
  }, {
    iso: 'GUM',
    name: 'Guam',
    url: '/images/lb-ui/flags/gum-3f5fbb02c168c0c678e557c0e002c4a00e81c002.svg'
  }, {
    iso: 'GUY',
    name: 'Guyana',
    url: '/images/lb-ui/flags/guy-d5e3f8c6d0b55ca4aa61b8d65553f3028d473a1f.svg'
  }, {
    iso: 'HKG',
    name: 'Hong Kong',
    url: '/images/lb-ui/flags/hkg-d0d413772b7f53137edbb4e999490643904c9ffd.svg'
  }, {
    iso: 'HMD',
    name: 'Heard Island And McDonald Islands',
    url: '/images/lb-ui/flags/hmd-5331cae8a6022fc8f237f022b9a97ff377fca767.svg'
  }, {
    iso: 'HND',
    name: 'Honduras',
    url: '/images/lb-ui/flags/hnd-cbdd188e8ebed7d220b490a99c688c40e67c38db.svg'
  }, {
    iso: 'HRV',
    name: 'Croatia',
    url: '/images/lb-ui/flags/hrv-072541f84c343a91503ce469c5cdd804f4561fc5.svg'
  }, {
    iso: 'HTI',
    name: 'Haiti',
    url: '/images/lb-ui/flags/hti-d2a928801c37f23d89c0fd299111c7533ebcf994.svg'
  }, {
    iso: 'HUN',
    name: 'Hungary',
    url: '/images/lb-ui/flags/hun-3287f7b8565501caec51cf6232cb0f2f16b70cca.svg'
  }, {
    iso: 'IDN',
    name: 'Indonesia',
    url: '/images/lb-ui/flags/idn-57d9a3a28c561c2b0fad1b161e5666a9f4c54f07.svg'
  }, {
    iso: 'IMN',
    name: 'Isle Of Man',
    url: '/images/lb-ui/flags/imn-932ad1c66e5a5a50f18da808067bfd2f7a473976.svg'
  }, {
    iso: 'IND',
    name: 'India',
    url: '/images/lb-ui/flags/ind-b966564adf2406d9d9a4fbf8e8422175afc7d4d1.svg'
  }, {
    iso: 'INT',
    name: 'International',
    url: '/images/lb-ui/flags/int-52d9a94aca1868baeeba4dca71b92b73a36068e0.svg'
  }, {
    iso: 'IOT',
    name: 'British Indian Ocean Territory',
    url: '/images/lb-ui/flags/iot-96fbdbf7835ad477a33487f032f34204db4a9832.svg'
  }, {
    iso: 'IRL',
    name: 'Ireland',
    url: '/images/lb-ui/flags/irl-3707bdc6558a675c1a5d49e2f4a42ca3a26cc246.svg'
  }, {
    iso: 'IRN',
    name: 'Iran, Islamic Republic Of',
    url: '/images/lb-ui/flags/irn-b4bff218128b3a81acf4f0ee7a061b27866592f6.svg'
  }, {
    iso: 'IRQ',
    name: 'Iraq',
    url: '/images/lb-ui/flags/irq-5f2b64e2f36750c1b0a78f79e1dcbf75ce8815e3.svg'
  }, {
    iso: 'ISL',
    name: 'Iceland',
    url: '/images/lb-ui/flags/isl-994327556d8f7b0ee3c7cd683ce9c70cc54ea9c8.svg'
  }, {
    iso: 'ISR',
    name: 'Israel',
    url: '/images/lb-ui/flags/isr-047c6cd7ad14d01754f2553122768c63bf2f0643.svg'
  }, {
    iso: 'ITA',
    name: 'Italy',
    url: '/images/lb-ui/flags/ita-8b1b777dc3ff33f350694ab7229be31ba547dd2c.svg'
  }, {
    iso: 'JAM',
    name: 'Jamaica',
    url: '/images/lb-ui/flags/jam-214053ff5332b0fc13ccbf4f1c9c3e696c6f92d1.svg'
  }, {
    iso: 'JEY',
    name: 'Jersey',
    url: '/images/lb-ui/flags/jey-c53350981aadbed75372d1a9c955bd2de0501d53.svg'
  }, {
    iso: 'JOR',
    name: 'Jordan',
    url: '/images/lb-ui/flags/jor-8f61b53c690b5e25836a9bcf4d3a78e9d9d79810.svg'
  }, {
    iso: 'JPN',
    name: 'Japan',
    url: '/images/lb-ui/flags/jpn-8dbdb847d0e5092fe9307a81c61334670de117c5.svg'
  }, {
    iso: 'KAZ',
    name: 'Kazakhstan',
    url: '/images/lb-ui/flags/kaz-ca630eab003e9d188a321267cb1e59b604e11004.svg'
  }, {
    iso: 'KEN',
    name: 'Kenya',
    url: '/images/lb-ui/flags/ken-35d7d35eb00e4cf9d89a2f561b8830d85475b17c.svg'
  }, {
    iso: 'KGZ',
    name: 'Kyrgyzstan',
    url: '/images/lb-ui/flags/kgz-f67fd7ae5db8e223319becfa90fda0a8671d32e6.svg'
  }, {
    iso: 'KHM',
    name: 'Cambodia',
    url: '/images/lb-ui/flags/khm-aab3de25a18d2d32dcdb76ece33a45eaeda43481.svg'
  }, {
    iso: 'KIR',
    name: 'Kiribati',
    url: '/images/lb-ui/flags/kir-8e00d83d0764b66c87b3c1c148b035547fab8a01.svg'
  }, {
    iso: 'KNA',
    name: 'Saint Kitts And Nevis',
    url: '/images/lb-ui/flags/kna-ae3b40eb6aa3f883c1166ef52484fb38e6905f60.svg'
  }, {
    iso: 'KOR',
    name: 'Korea, Republic Of',
    url: '/images/lb-ui/flags/kor-b62212bd47d80023bf00585179faf29a60207653.svg'
  }, {
    iso: 'KWT',
    name: 'Kuwait',
    url: '/images/lb-ui/flags/kwt-86493e22cd763aa6cdc614ce2bfc7ac1a349fafa.svg'
  }, {
    iso: 'LAO',
    name: 'Lao People&#x27;s Democratic Republic',
    url: '/images/lb-ui/flags/lao-366eaaa18bb1d1f7c2ec64ae8fb148de22015314.svg'
  }, {
    iso: 'LBN',
    name: 'Lebanon',
    url: '/images/lb-ui/flags/lbn-4fd42aa97ebb6bfcf04e1a798725606707abfdbf.svg'
  }, {
    iso: 'LBR',
    name: 'Liberia',
    url: '/images/lb-ui/flags/lbr-a171d31dcc398c6f16a3856c3560e3531951e9da.svg'
  }, {
    iso: 'LBY',
    name: 'Libya',
    url: '/images/lb-ui/flags/lby-6a90d9a9e4962b35cdd54cc4cb3b21748c24e1da.svg'
  }, {
    iso: 'LCA',
    name: 'Saint Lucia',
    url: '/images/lb-ui/flags/lca-f38433e115cce1955af71cfd85dad802c9b31f9c.svg'
  }, {
    iso: 'LIE',
    name: 'Liechtenstein',
    url: '/images/lb-ui/flags/lie-b1b3c42e62438b9e0dd8986c4144640912e49762.svg'
  }, {
    iso: 'LKA',
    name: 'Sri Lanka',
    url: '/images/lb-ui/flags/lka-ef913189c07634f56ef2c648ef6a88c650138eac.svg'
  }, {
    iso: 'LSO',
    name: 'Lesotho',
    url: '/images/lb-ui/flags/lso-6385780dc78e0a4843a3f3d5394b9daa8f8891bb.svg'
  }, {
    iso: 'LTU',
    name: 'Lithuania',
    url: '/images/lb-ui/flags/ltu-a1067c5c63143f29bc9b64412c5e1357d391506a.svg'
  }, {
    iso: 'LUX',
    name: 'Luxembourg',
    url: '/images/lb-ui/flags/lux-015f25d52042a7716b2eaffb4e0f69801e3e0738.svg'
  }, {
    iso: 'LVA',
    name: 'Latvia',
    url: '/images/lb-ui/flags/lva-645f72a52a3d2e355a45f2c572c7f6bd53ce6f25.svg'
  }, {
    iso: 'MAC',
    name: 'Macao',
    url: '/images/lb-ui/flags/mac-442876421a574b75343021d5c533b9a0470bdf8a.svg'
  }, {
    iso: 'MAF',
    name: 'Saint Martin',
    url: '/images/lb-ui/flags/maf-2078590e9c4ef1b82622faf09b26b1b3d24f4bd3.svg'
  }, {
    iso: 'MAR',
    name: 'Morocco',
    url: '/images/lb-ui/flags/mar-ced64e57f6861268fa285a768146a4c3d471505f.svg'
  }, {
    iso: 'MCO',
    name: 'Monaco',
    url: '/images/lb-ui/flags/mco-c85a610ddbe7c95ca0def97cb8eaac75dcca6851.svg'
  }, {
    iso: 'MDA',
    name: 'Moldova',
    url: '/images/lb-ui/flags/mda-949e76eb98b1190f0fbf47f6638a73041924d51e.svg'
  }, {
    iso: 'MDG',
    name: 'Madagascar',
    url: '/images/lb-ui/flags/mdg-2ddd0c1b25a6cd3a256c8fadc51e15005e1be20d.svg'
  }, {
    iso: 'MDV',
    name: 'Maldives',
    url: '/images/lb-ui/flags/mdv-558322b57c0562cf461c19898c824936e2d95e77.svg'
  }, {
    iso: 'MEX',
    name: 'Mexico',
    url: '/images/lb-ui/flags/mex-49d4025bacff98bcc28ac39931cea4507acd54df.svg'
  }, {
    iso: 'MHL',
    name: 'Marshall Islands',
    url: '/images/lb-ui/flags/mhl-abaed850c23dc16f6a2bb817146265a67b5c259d.svg'
  }, {
    iso: 'MKD',
    name: 'Macedonia, The Former Yugoslav Republic Of',
    url: '/images/lb-ui/flags/mkd-0a6daec6f3525777255a347cde1bfeeaa4cd4d4d.svg'
  }, {
    iso: 'MLI',
    name: 'Mali',
    url: '/images/lb-ui/flags/mli-5404bb19a7a1e20ef8901b6d4aecd165fe52a7b8.svg'
  }, {
    iso: 'MLT',
    name: 'Malta',
    url: '/images/lb-ui/flags/mlt-da9db7e5d4b9e6d14b92649a6c141f9aea25df87.svg'
  }, {
    iso: 'MMR',
    name: 'Myanmar',
    url: '/images/lb-ui/flags/mmr-3dddf56e83f9eccd09646f5bbf93371e81701757.svg'
  }, {
    iso: 'MNE',
    name: 'Montenegro',
    url: '/images/lb-ui/flags/mne-f36f7b575728708af3aa28853a6900c9b3ce0ae1.svg'
  }, {
    iso: 'MNG',
    name: 'Mongolia',
    url: '/images/lb-ui/flags/mng-d35c0d0e07ccd05e003d94a979cabf66c0f5ef75.svg'
  }, {
    iso: 'MNP',
    name: 'Northern Mariana Islands',
    url: '/images/lb-ui/flags/mnp-435cfb53fe505cf920a9720804c8ce23d4abb041.svg'
  }, {
    iso: 'MOZ',
    name: 'Mozambique',
    url: '/images/lb-ui/flags/moz-7ea42db554492992fd3ed74797953d6622f54663.svg'
  }, {
    iso: 'MRT',
    name: 'Mauritania',
    url: '/images/lb-ui/flags/mrt-fde2ce5cee886573c3e1a7eb4af4beaa4db11c4b.svg'
  }, {
    iso: 'MSR',
    name: 'Montserrat',
    url: '/images/lb-ui/flags/msr-6f37e400f3ff19168992155be343f32879fdc0e2.svg'
  }, {
    iso: 'MTQ',
    name: 'Martinique',
    url: '/images/lb-ui/flags/mtq-2078590e9c4ef1b82622faf09b26b1b3d24f4bd3.svg'
  }, {
    iso: 'MUS',
    name: 'Mauritius',
    url: '/images/lb-ui/flags/mus-a3e65284dc4737e2daf3d5ee423162d529cb72ab.svg'
  }, {
    iso: 'MWI',
    name: 'Malawi',
    url: '/images/lb-ui/flags/mwi-1df20f58796bd62481323056b1e499a49e92c260.svg'
  }, {
    iso: 'MYS',
    name: 'Malaysia',
    url: '/images/lb-ui/flags/mys-85295ccca6206f27bef5f4c0d5ee1699d62466ab.svg'
  }, {
    iso: 'MYT',
    name: 'Mayotte',
    url: '/images/lb-ui/flags/myt-2078590e9c4ef1b82622faf09b26b1b3d24f4bd3.svg'
  }, {
    iso: 'NAM',
    name: 'Namibia',
    url: '/images/lb-ui/flags/nam-aafa5a74d6205a0eb7fca625f0d7b1fbb0889d05.svg'
  }, {
    iso: 'NCL',
    name: 'New Caledonia',
    url: '/images/lb-ui/flags/ncl-2078590e9c4ef1b82622faf09b26b1b3d24f4bd3.svg'
  }, {
    iso: 'NER',
    name: 'Niger',
    url: '/images/lb-ui/flags/ner-05e6003a504a29805a6477ef9801b79defcf058d.svg'
  }, {
    iso: 'NFK',
    name: 'Norfolk Island',
    url: '/images/lb-ui/flags/nfk-6753b127bda7b1a8b96bb4900e5137d65fb12cfe.svg'
  }, {
    iso: 'NGA',
    name: 'Nigeria',
    url: '/images/lb-ui/flags/nga-4ef7338143d2d06e83f7f924f6a1257d99730302.svg'
  }, {
    iso: 'NIC',
    name: 'Nicaragua',
    url: '/images/lb-ui/flags/nic-918d7ed68655b71bfb3a18f1f5b3b1e0b8e28217.svg'
  }, {
    iso: 'NIR',
    name: 'Northern Ireland',
    url: '/images/lb-ui/flags/nir-a6411b52fcb6f7666daae768370f0fa72dc747b0.svg'
  }, {
    iso: 'NIU',
    name: 'Niue',
    url: '/images/lb-ui/flags/niu-1ebb6f0b606728c13cd9073302b42027d76482b1.svg'
  }, {
    iso: 'NLD',
    name: 'Netherlands',
    url: '/images/lb-ui/flags/nld-fa1b3d8166dd219fb65451416c665b357cad1e18.svg'
  }, {
    iso: 'NOR',
    name: 'Norway',
    url: '/images/lb-ui/flags/nor-b6055bfea95e9146a45c9eef16c7e6fa15dc7f5b.svg'
  }, {
    iso: 'NPL',
    name: 'Nepal',
    url: '/images/lb-ui/flags/npl-aac345d7baa934e347e167b6152abf7b0949ee05.svg'
  }, {
    iso: 'NRU',
    name: 'Nauru',
    url: '/images/lb-ui/flags/nru-eefd1d90468b35cc484ae277f69a43b3efb659be.svg'
  }, {
    iso: 'NZL',
    name: 'New Zealand',
    url: '/images/lb-ui/flags/nzl-ffbb1b6d41763c7f9740240c535b6a823a1fb612.svg'
  }, {
    iso: 'OMN',
    name: 'Oman',
    url: '/images/lb-ui/flags/omn-823c71d269961789bf9edcd32768b27ef99babad.svg'
  }, {
    iso: 'PAK',
    name: 'Pakistan',
    url: '/images/lb-ui/flags/pak-4e85c07dd9f4e9c50b25e1069ce3e94ca99a7eff.svg'
  }, {
    iso: 'PAN',
    name: 'Panama',
    url: '/images/lb-ui/flags/pan-d0b31e5b1677e9a8290e273a204169212252638a.svg'
  }, {
    iso: 'PCN',
    name: 'Pitcairn',
    url: '/images/lb-ui/flags/pcn-0abbeb7bfe87e19f98d7f58562244b582627c74a.svg'
  }, {
    iso: 'PER',
    name: 'Peru',
    url: '/images/lb-ui/flags/per-77eb87da0a478f37bfaec5d18cc932a9a69b6b20.svg'
  }, {
    iso: 'PHL',
    name: 'Philippines',
    url: '/images/lb-ui/flags/phl-6d91ca1a3b2bb77fdbdbbf269b72d81561d09d2b.svg'
  }, {
    iso: 'PLW',
    name: 'Palau',
    url: '/images/lb-ui/flags/plw-67475d6e544c5edf1078d5c6bd56b0c5d3113c84.svg'
  }, {
    iso: 'PNG',
    name: 'Papua New Guinea',
    url: '/images/lb-ui/flags/png-9c85eecdccd9939eeb7b42555a5e5aa71fef978c.svg'
  }, {
    iso: 'POL',
    name: 'Poland',
    url: '/images/lb-ui/flags/pol-c1e466c219942aa3c336a4e02df4da18cf34371d.svg'
  }, {
    iso: 'PRI',
    name: 'Puerto Rico',
    url: '/images/lb-ui/flags/pri-91c3e18547fc0724639e2e44bfe0e8b40c083558.svg'
  }, {
    iso: 'PRK',
    name: 'Korea, Democratic People&#x27;s Republic Of',
    url: '/images/lb-ui/flags/prk-58fcaf0f6b7d99bf849f881c8d7b0bbaefe83b7a.svg'
  }, {
    iso: 'PRT',
    name: 'Portugal',
    url: '/images/lb-ui/flags/prt-5c3095dbcf5b80343849bab3015fc4e666f7e526.svg'
  }, {
    iso: 'PRY',
    name: 'Paraguay',
    url: '/images/lb-ui/flags/pry-ad92869d4485b08f6bb402e8294653c6396d178b.svg'
  }, {
    iso: 'PSE',
    name: 'Palestinian Territory, Occupied',
    url: '/images/lb-ui/flags/pse-888aeb1f01dbf490647921c24b755f6c1d7b80c2.svg'
  }, {
    iso: 'PYF',
    name: 'French Polynesia',
    url: '/images/lb-ui/flags/pyf-d1d4b3b59743c44905a901e545a571c4a335ae0c.svg'
  }, {
    iso: 'QAT',
    name: 'Qatar',
    url: '/images/lb-ui/flags/qat-9901987920aba20465f11654d877c22cdb1bb9ba.svg'
  }, {
    iso: 'REU',
    name: 'Reunion',
    url: '/images/lb-ui/flags/reu-2078590e9c4ef1b82622faf09b26b1b3d24f4bd3.svg'
  }, {
    iso: 'ROU',
    name: 'Romania',
    url: '/images/lb-ui/flags/rou-5e8d69fa41d9094a083a30a688c1751822799d9e.svg'
  }, {
    iso: 'RUS',
    name: 'Russian Federation',
    url: '/images/lb-ui/flags/rus-b3d2e4892d0c8a2679ea4f68aafe8168f87e01c2.svg'
  }, {
    iso: 'RWA',
    name: 'Rwanda',
    url: '/images/lb-ui/flags/rwa-321019c605d903e326a3f7934e35321c82306abc.svg'
  }, {
    iso: 'SAU',
    name: 'Saudi Arabia',
    url: '/images/lb-ui/flags/sau-a607dbd4d4d3701624d804cf7898b63c2e3d7589.svg'
  }, {
    iso: 'SCO',
    name: 'Scotland',
    url: '/images/lb-ui/flags/sco-00f1c8f684f9b1880982358a73ce7b68b5c403ee.svg'
  }, {
    iso: 'SDN',
    name: 'Sudan',
    url: '/images/lb-ui/flags/sdn-a48804ce7ebd5c8adae12d03521ed8a5c763d06a.svg'
  }, {
    iso: 'SEN',
    name: 'Senegal',
    url: '/images/lb-ui/flags/sen-bd21561dd0b944ef905e86f4df5e3a426c438ebe.svg'
  }, {
    iso: 'SGP',
    name: 'Singapore',
    url: '/images/lb-ui/flags/sgp-ee9556adf094a27a97d9c5371342b61484284284.svg'
  }, {
    iso: 'SGS',
    name: 'South Georgia And The South Sandwich Islands',
    url: '/images/lb-ui/flags/sgs-97bcbb5e0c179aeb5a14b437ce4755f90f1ba439.svg'
  }, {
    iso: 'SHN',
    name: 'Saint Helena, Ascension And Tristan Da Cunha',
    url: '/images/lb-ui/flags/shn-32aaffd4bb6a240a4e58cd1890c638c6ddc2235c.svg'
  }, {
    iso: 'SJM',
    name: 'Svalbard And Jan Mayen',
    url: '/images/lb-ui/flags/sjm-b6055bfea95e9146a45c9eef16c7e6fa15dc7f5b.svg'
  }, {
    iso: 'SLB',
    name: 'Solomon Islands',
    url: '/images/lb-ui/flags/slb-683e08a402942eb4e8de7442719da138526d23e8.svg'
  }, {
    iso: 'SLE',
    name: 'Sierra Leone',
    url: '/images/lb-ui/flags/sle-439feddbdcbc825d661e51873c1b72492a34ffb1.svg'
  }, {
    iso: 'SLV',
    name: 'El Salvador',
    url: '/images/lb-ui/flags/slv-04d3004671115330c0105f2734b3276bc581c7ad.svg'
  }, {
    iso: 'SMR',
    name: 'San Marino',
    url: '/images/lb-ui/flags/smr-34028f3cd4593ff92164d76d41cee3a43d25c8f5.svg'
  }, {
    iso: 'SOM',
    name: 'Somalia',
    url: '/images/lb-ui/flags/som-da3014a6f1e1c7d7d71f9e8b28458b1d5d2260cc.svg'
  }, {
    iso: 'SPM',
    name: 'Saint Pierre And Miquelon',
    url: '/images/lb-ui/flags/spm-2078590e9c4ef1b82622faf09b26b1b3d24f4bd3.svg'
  }, {
    iso: 'SRB',
    name: 'Serbia',
    url: '/images/lb-ui/flags/srb-d660fe2fbf3355e5f23387bde3442c00474e5a0b.svg'
  }, {
    iso: 'SSD',
    name: 'South Sudan',
    url: '/images/lb-ui/flags/ssd-2df87cfff1acc12698e30944b4ed1e5b74edba53.svg'
  }, {
    iso: 'STP',
    name: 'Sao Tome and Principe',
    url: '/images/lb-ui/flags/stp-f402e511f0fe650a1e57b8bcf92091f9683fb29e.svg'
  }, {
    iso: 'SUR',
    name: 'Suriname',
    url: '/images/lb-ui/flags/sur-2d8c90075d0939c825d120c8cd4385b07df1ad38.svg'
  }, {
    iso: 'SVK',
    name: 'Slovakia',
    url: '/images/lb-ui/flags/svk-87ff185c5d89f44778acb38057b0ab5dad9d155a.svg'
  }, {
    iso: 'SVN',
    name: 'Slovenia',
    url: '/images/lb-ui/flags/svn-ef711fd0d22e5808ce9558baf86d43cb6f1d29f7.svg'
  }, {
    iso: 'SWE',
    name: 'Sweden',
    url: '/images/lb-ui/flags/swe-dde258a0f9b09cf08518ae5e59846d0ce705132f.svg'
  }, {
    iso: 'SWZ',
    name: 'Swaziland',
    url: '/images/lb-ui/flags/swz-f9b02f1d3f28d1ab9974d8250ae7a5ce86c29c68.svg'
  }, {
    iso: 'SXM',
    name: 'Sint Maarten',
    url: '/images/lb-ui/flags/sxm-6dd8013192d59c86b95c271c201cdd8480028f5c.svg'
  }, {
    iso: 'SYC',
    name: 'Seychelles',
    url: '/images/lb-ui/flags/syc-1cf30a9fdcc0da258e089ae03e618d80a7cb13a3.svg'
  }, {
    iso: 'SYR',
    name: 'Syrian Arab Republic',
    url: '/images/lb-ui/flags/syr-b3c3abe5bddedf7b21c08eef7722768bee69794f.svg'
  }, {
    iso: 'TCA',
    name: 'Turks And Caicos Islands',
    url: '/images/lb-ui/flags/tca-a26f30f2109b1faaa4b091afdadaad0c4ded6e29.svg'
  }, {
    iso: 'TCD',
    name: 'Chad',
    url: '/images/lb-ui/flags/tcd-21d7ffe833adc23768bccc7752f66125cc01a05e.svg'
  }, {
    iso: 'TGO',
    name: 'Togo',
    url: '/images/lb-ui/flags/tgo-a8a021e8fa13cf81926e66fbdb4eca11faa555b4.svg'
  }, {
    iso: 'THA',
    name: 'Thailand',
    url: '/images/lb-ui/flags/tha-68d96824102bf202c620be10891b378bf4135e28.svg'
  }, {
    iso: 'TJK',
    name: 'Tajikistan',
    url: '/images/lb-ui/flags/tjk-8fd833b5d100b56cc5640c22205c50d97113af48.svg'
  }, {
    iso: 'TKL',
    name: 'Tokelau',
    url: '/images/lb-ui/flags/tkl-2664c3b41754377257cacab26eb57569e61d7134.svg'
  }, {
    iso: 'TKM',
    name: 'Turkmenistan',
    url: '/images/lb-ui/flags/tkm-2893e0fda195b8d8396fcd6ace523a132fe08c31.svg'
  }, {
    iso: 'TLS',
    name: 'Timor-Leste, Democratic Republic of',
    url: '/images/lb-ui/flags/tls-f832873eab420f0799660412a3d3028de3885bf6.svg'
  }, {
    iso: 'TON',
    name: 'Tonga',
    url: '/images/lb-ui/flags/ton-65769cc6cc969b937249320cf9ea59f08954af48.svg'
  }, {
    iso: 'TTO',
    name: 'Trinidad And Tobago',
    url: '/images/lb-ui/flags/tto-3b883cf476a8612ea701ff0cbff3a879b43324be.svg'
  }, {
    iso: 'TUN',
    name: 'Tunisia',
    url: '/images/lb-ui/flags/tun-43867337a735cadad4ba00133610819adf1c602c.svg'
  }, {
    iso: 'TUR',
    name: 'Turkey',
    url: '/images/lb-ui/flags/tur-b8e39e0dd9d927c93fb24cacb38ef0f0ecd97401.svg'
  }, {
    iso: 'TUV',
    name: 'Tuvalu',
    url: '/images/lb-ui/flags/tuv-b56647da992a30570156359df362563f28816f88.svg'
  }, {
    iso: 'TWN',
    name: 'Taiwan',
    url: '/images/lb-ui/flags/twn-90e2cc842c87a1f85581935ddbed60337589825d.svg'
  }, {
    iso: 'TZA',
    name: 'Tanzania, United Republic Of',
    url: '/images/lb-ui/flags/tza-bb75af59b4f447414da8cd41b7b58491fc4d8e6f.svg'
  }, {
    iso: 'UGA',
    name: 'Uganda',
    url: '/images/lb-ui/flags/uga-0c71cfd32a882efa8ad8b85f6db58ca807c18979.svg'
  }, {
    iso: 'UKR',
    name: 'Ukraine',
    url: '/images/lb-ui/flags/ukr-9085e12bf3a5ea7193cd8731584b9d6cbd58b8ac.svg'
  }, {
    iso: 'UMI',
    name: 'United States Minor Outlying Islands',
    url: '/images/lb-ui/flags/umi-75476a50b869acf58d48cf0561fc9187ba58d787.svg'
  }, {
    iso: 'UN',
    name: 'United Nations',
    url: '/images/lb-ui/flags/un-f0ba520f5dedb7da7f77784ed39ace370a341230.svg'
  }, {
    iso: 'URY',
    name: 'Uruguay',
    url: '/images/lb-ui/flags/ury-e2d15038940dbae538ac4ef7b3b76949b022c6ec.svg'
  }, {
    iso: 'USA',
    name: 'United States',
    url: '/images/lb-ui/flags/usa-2d74155327a2dde2bf887152abff64796d96fc70.svg'
  }, {
    iso: 'UZB',
    name: 'Uzbekistan',
    url: '/images/lb-ui/flags/uzb-a19ab63a820d96f7c2b892b9fede8ebb5dce11d5.svg'
  }, {
    iso: 'VAT',
    name: 'Vatican City State',
    url: '/images/lb-ui/flags/vat-99fdd3a14d717840e3413715aac8951c5d670cc6.svg'
  }, {
    iso: 'VCT',
    name: 'Saint Vincent And The Grenadines',
    url: '/images/lb-ui/flags/vct-6b25dc957a593b7c88284823b3aa16dd90338a3f.svg'
  }, {
    iso: 'VEN',
    name: 'Venezuela, Bolivarian Republic Of',
    url: '/images/lb-ui/flags/ven-a11f22fbae0c55245d6e13ed26a58f1dc56bdcf6.svg'
  }, {
    iso: 'VGB',
    name: 'Virgin Islands (British)',
    url: '/images/lb-ui/flags/vgb-a3aee8ae65c2801fdcc94ff9b718f51b5f926101.svg'
  }, {
    iso: 'VIR',
    name: 'Virgin Islands (US)',
    url: '/images/lb-ui/flags/vir-f54aea076e500db65a02fa8d89737ae5b84df47b.svg'
  }, {
    iso: 'VNM',
    name: 'Viet Nam',
    url: '/images/lb-ui/flags/vnm-1b2dfa38363f5337c6a82afaeecaeac8fc28d41c.svg'
  }, {
    iso: 'VUT',
    name: 'Vanuatu',
    url: '/images/lb-ui/flags/vut-d092e6ac5beb4d6663f780705d38a8ff6123cc7a.svg'
  }, {
    iso: 'WAL',
    name: 'Wales',
    url: '/images/lb-ui/flags/wal-d36270c4d6294de3491cf71baaa04f48ae278858.svg'
  }, {
    iso: 'WLF',
    name: 'Wallis And Futuna',
    url: '/images/lb-ui/flags/wlf-2078590e9c4ef1b82622faf09b26b1b3d24f4bd3.svg'
  }, {
    iso: 'WSM',
    name: 'Samoa',
    url: '/images/lb-ui/flags/wsm-3c5d75b18a48b1b3345da17946bcce6dd7346b70.svg'
  }, {
    iso: 'XXK',
    name: 'Kosovo',
    url: '/images/lb-ui/flags/xxk-e763fa3adf333fc2357e5054ea257cf52784f02e.svg'
  }, {
    iso: 'YEM',
    name: 'Yemen',
    url: '/images/lb-ui/flags/yem-f30030ab19002b3344bb4ef86f97c9a47e792885.svg'
  }, {
    iso: 'ZAF',
    name: 'South Africa',
    url: '/images/lb-ui/flags/zaf-6d46d239bdb06ffe213ab6ce022a2582c936044d.svg'
  }, {
    iso: 'ZMB',
    name: 'Zambia',
    url: '/images/lb-ui/flags/zmb-8c8acceb4eed9f659fe25a7b7275f5bcb5aade19.svg'
  }, {
    iso: 'ZWE',
    name: 'Zimbabwe',
    url: '/images/lb-ui/flags/zwe-0362fad0f1c506efdb79c2cd36d5faa5f6ae74ee.svg'
  }];
  svs.lb_ui.flags.getFlag = function (iso, key) {
    if (iso) {
      const flag = availiableFlags.filter(item => {
        return item.iso.toLowerCase() === iso.toLowerCase();
      })[0];
      if (flag) {
        let value = cdn + flag.url;
        if (typeof key === 'string' || key instanceof String) {
          value = flag[key];
        }
        return value;
      } else {
        return 'no-flag-found.svg';
      }
    }
  };

  if (typeof exports === 'object') {
    module.exports = svs.lb_ui.flags;
  }
})(svs);

 })(window);